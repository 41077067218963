<script>
import { Line } from "vue-chartjs";

export default {
  name: "InsightsChart",
  extends: Line,
  props: {
    chartdata: { type: Object, default: null },
    options: { type: Object, default: null }
  },
  mounted() {
    // Extra space between chart and Legend
    this.addPlugin({
      beforeInit(chart) {
        chart.legend.afterFit = function() {
          this.height = this.height + 20;
        };
      }
    });
    this.renderChart(this.chartdata, this.options);
  },
};
</script>