<template>
  <div class="insights">
    <section id="insights-head">
      <img src="@/assets/images/insights-illustration.svg" alt="Insights illustration" />
      <h1>Insights</h1>
      <form @submit.prevent class="kpis">
        <div class="kpi">
          <h4>CA total potentiel</h4>
          <label for="input-overview-potential">Insérez une valeur</label>
          <div class="input-row">
            <input
              id="input-overview-potential"
              type="number"
              v-model.lazy="overview.potential"
              step=".01" @change="updateOverview">
            <span>€</span>
          </div>
          <!-- <strong>{{ overview.potential}}€</strong> -->
        </div>
        <div class="kpi">
          <h4>CA total AO remportés</h4>
          <label for="input-overview-gained">Insérez une valeur</label>
          <div class="input-row">
            <input
              id="input-overview-gained"
              type="number"
              v-model.lazy="overview.gained"
              step=".01"
              @change="updateOverview">
              <span>€</span>
          </div>
          <!-- <strong>{{ overview.gained}}€</strong> -->
        </div>
        <div class="kpi">
          <h4>Nombre d'AO analysés</h4>
          <strong>{{ overview.treated.total }}</strong>
        </div>
      </form>
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/images/icons/i-play.svg" alt="Icone play" />
        Accéder à la liste de documents
      </router-link>
    </section>

    <section id="insights-body">
      <h1>Aperçu du traitement des lignes</h1>
      <h3>Choisir une période</h3>
      <form @submit.prevent>
        <div
          v-click-outside="() => dropdownOpen = false"
          class="dropdown input bordered"
          :class="{ opened: dropdownOpen }"
          @click="dropdownOpen = !dropdownOpen"
        >
          <span>{{ $options.ranges[range] }}</span>
          <div class="dropdown-content">
            <div @click.stop="selectRange('month')">Mois</div>
            <div @click.stop="selectRange('year')">Année</div>
          </div>
        </div>
        <div v-if="range === 'month'" class="paginator input bordered">
          <button @click="changeMonth(-1)">
            <img src="@/assets/images/icons/i-chevron-left-black.svg" alt="Page précédente" />
          </button>
          <span>{{ readableDate }}</span>
          <button @click="changeMonth(1)">
            <img src="@/assets/images/icons/i-chevron-right-black.svg" alt="Page suivante" />
          </button>
        </div>
        <div v-else-if="range === 'year'" class="paginator input bordered">
          <button @click="changeYear(-1)">
            <img src="@/assets/images/icons/i-chevron-left-black.svg" alt="Page précédente" />
          </button>
          <span>{{ readableDate }}</span>
          <button @click="changeYear(1)">
            <img src="@/assets/images/icons/i-chevron-right-black.svg" alt="Page suivante" />
          </button>
        </div>
      </form>
      <hr />
      <div class="stats">
        <div class="bordered box">
          <h4>Matchs automatiques sur les lignes validées</h4>
          <strong>{{ reducedSummary.suggested + reducedSummary.alternative }} sur {{ reducedSummary.total }} ({{ (percents.suggested + percents.alternative).toFixed(2) }}%)</strong>
        </div>
        <div class="bordered box">
          <h4>Matchs manuels sur les lignes validées</h4>
          <strong>{{ reducedSummary.manual }} sur {{ reducedSummary.total }} ({{ percents.manual.toFixed(2) }}%)</strong>
        </div>
        <div class="bordered box">
          <h4>Temps de traitement moyen par ligne</h4>
          <strong>{{readableTimeSpent(averageTimeSpent)}}</strong>
        </div>
      </div>
      <div class="chart-container bordered">
        <InsightsChart v-if="loaded" :chartdata="datacollection" :options="options" />
      </div>
    </section>
  </div>
</template>

<script>
import { capitalize } from '@/utils';
import InsightsChart from "@/components/InsightsChart.vue";
import { secondsToReadableDuration } from '@/filters';

export default {
  name: "Insights",
  components: { InsightsChart },
  created() {
    this.$options.ranges = {
      month: "Mois",
      year: "Année"
    };
    this.fetchData();
  },
  data() {
    return {
      loaded: false,
      dropdownOpen: false,
      range: "month",
      period: new Date(),
      datacollection: {},
      summary: {},
      averageTimeSpent: 0,
      overview: {
        gained: 0,
        potential: 0,
        treated: {
          completed: 0,
          total: 0,
        },
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          fontFamily: "Poppins",
          yAxes: [{
              display: true,
              ticks: {
                suggestedMin: 0,
                precision: 0,
              },
            },
          ],
        },

        legend: {
          labels: {
            usePointStyle: true,
            boxWidth: 20,
            padding: 30,
            fontFamily: "Poppins",
            fontColor: "#3D3D3E",
          },
          align: "end"
        },
        elements: {
          line: {
            tension: 0,
            fill: false,
          },
        },
        tooltips: {
          bodyFontStyle: "bold",
          bodySpacing: 10,
          callbacks: {
            title() {},
            label(tooltip, chart) {
              const dataset = chart.datasets[tooltip.datasetIndex];
              const previousValue = dataset.data[tooltip.index - 1];
              if (previousValue === undefined) return tooltip.value;

              const difference = (previousValue - parseInt(tooltip.value, 10)) * -1;
              return `${tooltip.value} (${difference >= 0 ? "+" : ""}${difference})`;
            }
          }
        }
      }
    };
  },
  computed: {
    readableDate() {
      const month = this.period.toLocaleDateString('fr-FR', { month: 'long' });
      const year = this.period.getFullYear();
      return (() => {
        switch (this.range) {
          case 'month': return `${month} ${year}`;
          case 'year': return year;
        }
      })();
    },
    reducedSummary() {
      const startObject = { suggested: 0, alternative: 0, manual: 0, total: 0 };
      return Object.entries(this.summary)
        .reduce((accumulator, [type, count]) => ({
          ...accumulator,
          [type]: count.reduce((carry, value) => carry += value, 0),
        }), startObject);
    },
    percents() {
      return Object.entries(this.reducedSummary)
        .reduce((accumulator, [type, count]) => ({
          ...accumulator,
          [type]: ((count / this.reducedSummary.total * 100) || 0),
        }), {});
    },
  },
  methods: {
    readableTimeSpent(timeSpent) {
      return secondsToReadableDuration(timeSpent, 'short');
    },
    updateOverview({ target }) {
      if (!target.validity.valid) {
        return target.reportValidity();
      }

      this.$axios.put('/insights', {}, {
        params: {
          // eslint-disable-next-line
          ...(({ treated, ...rest }) => rest)(this.overview),
        },
      })
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'Une valeur vient d’être modifié dans “Insights”',
          });
        })
        .catch(console.error);
    },
    selectRange(range) {
      this.range = range;
      this.dropdownOpen = false;
      this.fetchData();
    },
    changeMonth(position) {
      const newPeriod = new Date(this.period.getFullYear(), this.period.getMonth() + 1 * position, 1);
      if (newPeriod > new Date()) return;
      this.period = newPeriod;
      this.fetchData();
    },
    changeYear(position) {
      const newPeriod = new Date(this.period.getFullYear() + 1 * position, 0, 1);
      if (newPeriod > new Date() || newPeriod.getFullYear < 2019) return;
      this.period = newPeriod;
      this.fetchData();
    },
    fetchData() {
      this.loaded = false;
      this.$axios
        .get("/insights", {
          params: {
            period: this.range,
            month: this.period.getMonth() + 1,
            year: this.period.getFullYear(),
          }
        })
        .then(({ data }) => {
          this.fillData(data);
          this.loaded = true;
        })
        .catch(console.error);
    },
    fillData(data) {
      const labels = [];
      const startObject = {
        suggested: [],
        alternative: [],
        manual: [],
        total: []
      };
      const { dataset, averageTimeSpent, ...overview } = data;
      this.overview = overview;
      this.averageTimeSpent =  averageTimeSpent

      const dateFormatter = ([year, month, day]) => {
        const date = new Date(year, month - 1, day || 1);
        const formatters = {
          year: () => `${capitalize(date.toLocaleDateString('fr-FR', { month: 'long' }))} ${year}`,
          month: () => `${day}/${month}`,
        };
        return formatters[this.range]();
      };
      const computed = dataset
        .reduce((accumulator, entry) => {
          return (({ date, ...keys }) => {
            // eslint-disable-next-line
            labels.push(dateFormatter(date.split('-')));
            Object.entries(keys).forEach(([key, value]) => accumulator[key].push(value));
            return accumulator;
          })(entry);
        }, startObject);
      this.summary = computed;
      this.datacollection = {
        labels,
        datasets: [
          // {
          //   label: 'Aujourd\'hui',
          //   backgroundColor: '#E0E3F1',
          //   borderColor: '#E0E3F1',
          //   data: [...Array(10)].map(this.getRandomInt),
          // },
          {
            label: "Matchs automatiques",
            backgroundColor: "#6B3AD2",
            borderColor: "#6B3AD2",
            data: computed.suggested.map((val, i) => val + computed.alternative[i])
          },
          {
            label: "Matchs manuel",
            backgroundColor: "#98A2D3",
            borderColor: "#98A2D3",
            data: computed.manual
          }
          // {
          //   label: 'Nombre de documents importés',
          //   backgroundColor: '#B60F55',
          //   borderColor: '#B60F55',
          //   data: [...Array(10)].map(this.getRandomInt),
          // },
        ]
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  border: solid 1px #c8c8c8;
  border-radius: 6px;
}

section {
  display: flex;

  &#insights-body {
    padding: 30px 50px;
    flex-direction: column;
  }

  &#insights-head {
    padding: 20px 50px;
    background-color: #0f0455;

    > img {
      transform: translateY(20px);
    }

    h1 {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      align-self: center;
      margin: 0 25px;
    }

    .kpis {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 15px;

      .kpi {
        background-color: #fff;
        border-radius: 6px;
        padding: 12px;
        min-width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        h4 {
          font-size: 16px;
          color: #3d3d3e;
          font-weight: 600;
        }

        .input-row {
          display: flex;
          align-items: center
        }

        label {
          font-size: 14px;
          margin-bottom: 5px;
          opacity: .8;
          font-style: italic;
          font-weight: 300;
        }

        input {
          font-size: 18px;
          color: #0b2956;
          border: solid 1px #c9c9c9;
          padding: 5px;
          border-radius: 6px;
          width: 175px;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          & + span {
            font-weight: 600;
            color: #132954;
            font-size: 18px;
            margin-left: 10px;
          }
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      border-radius: 6px;
      background-color: #6f76b6;
      padding: 10px 15px;
      align-self: center;
      justify-self: flex-end;
      color: #fff;
      text-decoration: none;
      margin-left: auto;

      img {
        margin-right: 10px;
      }
    }
  }
}

h1,
h3,
h4 {
  color: #0b2956;
  margin: 0;
}

h1 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

h3 {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 14px;
}

form {
  display: flex;
}

.input {
  padding: 8px 15px;
  margin-right: 15px;
  height: 30px;
  display: flex;
  align-items: center;
  min-width: 130px;
  box-sizing: border-box;

  &.dropdown {
    padding-right: 30px;
    position: relative;
    background-image: url("~@/assets/images/icons/i-chevron-down.svg");
    background-repeat: no-repeat;
    background-position: center right 10px;
    cursor: pointer;

    &.opened {
      border-radius: 6px 6px 0 0;

      .dropdown-content {
        display: flex;
      }
    }

    .dropdown-content {
      position: absolute;
      display: none;
      flex-direction: column;
      top: 100%;
      background-color: #fff;
      z-index: 10;
      left: -1px;
      width: calc(100% + 2px);
      box-sizing: border-box;
      border: solid 1px #c8c8c8;
      border-radius: 0 0 6px 6px;
      font-weight: normal;
      font-size: 12px;

      > div {
        padding: 5px 15px;
        &:hover {
          background-color: rgba(#0b2956, 0.15);
        }
      }
    }
  }

  &.paginator {
    justify-content: space-between;
    width: 170px;
    padding: 8px 10px;

    button {
      border: none;
      background: none;
      padding: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    span {
      text-transform: capitalize;
      margin: 0 5px;
    }
  }

  span {
    font-size: 12px;
  }
}

hr {
  border: none;
  height: 1px;
  background-color: #c8c8c8;
  margin: 25px 0;
}

.stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;

  .box {
    padding: 12px;
    display: flex;
    flex-direction: column;

    h4 {
      font-weight: normal;
      font-size: 12px;
      color: #3d3d3e;
    }

    strong {
      font-size: 18px;
      margin: 8px 0;
      color: #0b2956;
    }
  }
}

.chart-container {
  height: 500px;
  padding: 15px;
  margin: 30px 0;
}
</style>
